"use client";

import { AppUser } from "@/types";
import { Profile } from "@/types/collection";
import { createClient } from "@/utils/supabase/client";
import { User } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import LoginButton from "./login-button";
import LoginProfileButton from "./login-profile-button";

export const dynamic = "force-dynamic";
export const revalidate = 0;

type loginMenuProps = {
  className?: string | undefined;
};

const LoginMenu = (props: loginMenuProps) => {
  const { className } = props;
  const supabase = createClient();
  const [user, setUser] = useState<AppUser>(null);
  // const [avatarUrl, setAvatarUrl] = useState<string>("");
  const [role, setRole] = useState<string>("user");

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user);
    });

    return () => subscription.unsubscribe();
  }, [supabase.auth]);

  useEffect(() => {
    async function fetchAvatar() {
      if (user?.id) {
        const { data } = await supabase
          .from("profiles")
          .select("*")
          .match({ id: user?.id })
          .single<Profile>();
        if (data) {
          // setAvatarUrl(data.avatar_url ? data.avatar_url : "");
          setRole(data.role ? data.role : "user");
        }
      } else {
        // setAvatarUrl("");
        setRole("user");
      }
    }
    fetchAvatar();
  }, [user, supabase]);

  return (
    <>
      {user ? (
        <LoginProfileButton
          // profileImageUrl={avatarUrl}
          role={role}
          className={className}
        />
      ) : (
        <LoginButton className={className} />
      )}
    </>
  );
};

export default LoginMenu;
