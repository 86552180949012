import { Slot } from "@radix-ui/react-slot";
import { Eye, EyeOff } from "lucide-react";
import * as React from "react";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  open: boolean;
}

const PasswordButtonToggle = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, asChild = false, open = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp className={className} type="button" ref={ref} {...props}>
        {open ? <Eye /> : <EyeOff />}
      </Comp>
    );
  },
);
PasswordButtonToggle.displayName = "PasswordButtonToggle";

export { PasswordButtonToggle };
