"use client";

import GetCurrentAppConfig from "@/actions/config/get-current-app-config";
import SiteLogo from "@/components/ui/logo";
import Link from "next/link";
import { usePathname } from "next/navigation";

type LinkSiteLogoProps = {
  className?: string | undefined;
  showName?: boolean;
};

const LinkSiteLogo = ({ className, showName = false }: LinkSiteLogoProps) => {
  const currentPath = usePathname();
  const appConfig = GetCurrentAppConfig();

  return currentPath === "/" ? (
    <div className={className}>
      <SiteLogo />
      {showName && (
        <span className="hidden pl-2 md:flex">{appConfig.name}</span>
      )}
    </div>
  ) : (
    <Link href="/" className={className}>
      <SiteLogo />
      {showName && (
        <span className="hidden pl-2 md:flex">{appConfig.name}</span>
      )}
    </Link>
  );
};

export default LinkSiteLogo;
