"use client";

import { CreateProfile } from "@/actions/user/create-profile";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { PasswordButtonToggle } from "@/components/ui/password-toggle";
import { sharedLoginConfig, sharedRouteConfig } from "@/config/shared";
import { GoogleIcon, LoadingDots } from "@/icons";
import { getUrl } from "@/lib/utils";
import type { formMode } from "@/lib/validation/login";
import {
  InitializePasswordResetSchema,
  PasswordResetSchema,
  SignInFormSchema,
  SignUpFormSchema,
} from "@/lib/validation/user";
import { createClient } from "@/utils/supabase/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import z from "zod";

const getLoginRedirectPath = (pathname?: string | null): string => {
  return (
    getUrl() +
    "/auth/callback" + // Required for PKCE authentication.
    "?redirect=" + // Passed to auth/route/callback to redirect after auth
    (pathname ? pathname : "/")
  );
};

// Sign In -----------
type signInFormValues = z.infer<typeof SignInFormSchema>;

const defaultValues: Partial<signInFormValues> = {
  email: "",
  password: "",
};
// Sign In -----------

// Sign Up -----------
type signUpFormValues = z.infer<typeof SignUpFormSchema>;

const signUpDefaultValues: Partial<signUpFormValues> = {
  email: "",
  password: "",
};
// Sign Up -----------

// Initialize Password Reset  -----------
type initializePasswordResetFormValues = z.infer<
  typeof InitializePasswordResetSchema
>;
const initializePasswordResetDefaultValues: Partial<initializePasswordResetFormValues> =
  {
    email: "",
  };
// Initialize Password Reset  -----------

// Password Reset  -----------
type PasswordResetFormValues = z.infer<typeof PasswordResetSchema>;
const passwordResetDefaultValues: Partial<PasswordResetFormValues> = {
  password: "",
};
// Password Reset  -----------

interface LoginSectionProps {
  setOpen?: any;
  defaultMode: formMode;
}

const LoginSection: React.FC<LoginSectionProps> = ({ defaultMode }) => {
  const supabase = createClient();

  const form = useForm<z.infer<typeof SignInFormSchema>>({
    resolver: zodResolver(SignInFormSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const signupForm = useForm<z.infer<typeof SignUpFormSchema>>({
    resolver: zodResolver(SignUpFormSchema),
    mode: "onChange",
    defaultValues: signUpDefaultValues,
  });

  const initalizePasswordResetForm = useForm<
    z.infer<typeof InitializePasswordResetSchema>
  >({
    resolver: zodResolver(InitializePasswordResetSchema),
    mode: "onChange",
    defaultValues: initializePasswordResetDefaultValues,
  });

  const passwordResetForm = useForm<z.infer<typeof PasswordResetSchema>>({
    resolver: zodResolver(PasswordResetSchema),
    mode: "onChange",
    defaultValues: passwordResetDefaultValues,
  });

  const [signInGoogleClicked, setSignInGoogleClicked] =
    useState<boolean>(false);
  const [signInClicked, setSignInClicked] = useState<boolean>(false);
  const [signUpClicked, setSignUpClicked] = useState<boolean>(false);
  const [initalizePasswordResetClicked, setInitalizePasswordResetClicked] =
    useState<boolean>(false);
  const [passwordResetClicked, setPasswordResetClicked] =
    useState<boolean>(false);
  const [mode, setMode] = useState<formMode>(defaultMode);
  const [togglePassword, setTogglePassword] = useState<boolean>(false);

  const router = useRouter();
  const currentPathname = usePathname();
  const redirectTo = getLoginRedirectPath(currentPathname);

  const googleLoginEnabled =
    process.env.NEXT_PUBLIC_SUPABASE_GOOGLE_LOGIN_ENABLED === "true";

  async function signInWithGoogle() {
    setSignInGoogleClicked(true);

    try {
      await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo,
          queryParams: {
            prompt: "consent",
          },
        },
      });
      router.refresh();
    } catch (e) {
      toast.error("Failed to sign in.");
    }

    setSignInGoogleClicked(false);
  }

  async function signInWithEmailAndPass(data: signInFormValues) {
    setSignInClicked(true);
    try {
      const { data: signInData, error } =
        await supabase.auth.signInWithPassword({
          email: data.email,
          password: data.password,
        });
      if (error) {
        toast.error("Failed to sign in.");
      }

      if (signInData && !error) {
        toast.success(sharedLoginConfig.signedIn);
        router.refresh();
      }
    } catch (e) {
      toast.error("Failed to sign in.");
    }
    setSignInClicked(false);
  }

  async function signUpWithEmailAndPass(data: signUpFormValues) {
    setSignUpClicked(true);
    try {
      const { data: signUpData, error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
      });
      if (!error && signUpData) {
        toast.success(
          "Signed up successfully. Please check and confirm your email.",
        );
        await CreateProfile(signUpData.user?.id);
        signupForm.setValue("email", "");
        signupForm.setValue("password", "");
        signupForm.setValue("confirm_password", "");

        router.refresh();
      } else {
        toast.error("Failed to sign up.");
      }
    } catch (e) {
      toast.error("Failed to sign up.");
    }
    setSignUpClicked(false);
  }

  async function passwordReset(data: initializePasswordResetFormValues) {
    setInitalizePasswordResetClicked(true);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(data.email, {
        redirectTo: `${getUrl()}/update-password`,
      });
      if (error) {
        toast.error("An error has occurred. Please try again.");
      } else {
        initalizePasswordResetForm.setValue("email", "");
        toast.success(
          "Password email request successfully. Please check your email.",
        );
      }
    } catch (e) {
      toast.error("An error has occurred. Please try again.");
    }
    setInitalizePasswordResetClicked(false);
  }

  async function updatePassword(data: PasswordResetFormValues) {
    setPasswordResetClicked(true);

    try {
      const { error } = await supabase.auth.updateUser({
        password: data.password,
      });
      if (error) {
        // todo find a better way than status code or message contains
        if (error.status === 422) {
          toast.error("New password should be different from the old password");
        } else {
          toast.error("An error has occurred. Please try again.");
        }
      } else {
        passwordResetForm.setValue("password", "");
        toast.success("Password updated successfully.");
        router.push(sharedRouteConfig.home);
      }
    } catch (e) {
      toast.error("An error has occurred. Please try again.");
    }

    setPasswordResetClicked(false);
  }

  return (
    <>
      <div className="flex max-w-md flex-col rounded-md p-6 sm:p-10">
        {mode === "LOGIN" && (
          <>
            <div className="mb-8 text-center">
              <h1 className="my-3 text-4xl font-bold">
                {sharedLoginConfig.signIn}
              </h1>
              <p className="text-sm dark:text-gray-400">
                {sharedLoginConfig.signInTagLine}
              </p>
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(signInWithEmailAndPass)}
                className="space-y-4"
              >
                <div className="space-y-4">
                  <div>
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-gray-600">Email</FormLabel>
                          <div className="mx-auto flex w-full max-w-md space-x-2">
                            <FormControl>
                              <Input
                                type="email"
                                autoComplete="email"
                                maxLength={100}
                                {...field}
                              />
                            </FormControl>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="space-y-4">
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-gray-600">
                            Password
                          </FormLabel>
                          <div className="mx-auto flex w-full max-w-md space-x-2">
                            <FormControl>
                              <Input
                                type={togglePassword ? "text" : "password"}
                                autoComplete="current-password"
                                maxLength={100}
                                {...field}
                              />
                            </FormControl>
                            <PasswordButtonToggle
                              onClick={() => setTogglePassword(!togglePassword)}
                              open={togglePassword}
                            />
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <Button
                      type="submit"
                      className="w-full rounded-md px-8 py-3 font-semibold"
                    >
                      {signInClicked ? <LoadingDots /> : `Sign In`}
                    </Button>
                  </div>
                </div>
              </form>
            </Form>
            <div className="flex justify-end pt-4">
              <button onClick={() => setMode("PASSWORD")}>
                <p className="px-6 text-center text-sm dark:text-gray-400">
                  Forgot your password?
                </p>
              </button>
            </div>
            <div className="flex justify-center pt-4">
              <button onClick={() => setMode("SIGNUP")}>
                <p className="px-6 text-center text-sm dark:text-gray-400">
                  Don&apos;t have an account yet? Sign up.
                </p>
              </button>
            </div>
            {/* Sign in buttons with Social accounts */}
            {googleLoginEnabled && (
              <div className="flex flex-col justify-center space-y-4 py-8 md:px-16">
                <button
                  disabled={signInGoogleClicked}
                  className={`${
                    signInGoogleClicked
                      ? "cursor-not-allowed border-gray-200 bg-gray-100"
                      : "border border-gray-200 bg-white text-black hover:bg-gray-50"
                  } flex h-10 w-[100px] items-center justify-center space-x-3 rounded-md border text-sm shadow-sm transition-all duration-75 focus:outline-none`}
                  onClick={() => signInWithGoogle()}
                >
                  {signInGoogleClicked ? (
                    <LoadingDots />
                  ) : (
                    <GoogleIcon className="h-5 w-5" />
                  )}
                </button>
              </div>
            )}
          </>
        )}
        {mode === "SIGNUP" && (
          <>
            <div className="mb-8 text-center">
              <h1 className="my-3 text-4xl font-bold">
                {sharedLoginConfig.signUp}
              </h1>
            </div>
            <Form {...signupForm}>
              <form
                onSubmit={signupForm.handleSubmit(signUpWithEmailAndPass)}
                className="space-y-4"
              >
                <div className="space-y-4">
                  <div>
                    <FormField
                      control={signupForm.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-gray-600">Email</FormLabel>
                          <div className="mx-auto flex w-full max-w-md space-x-2">
                            <FormControl>
                              <Input type="email" {...field} />
                            </FormControl>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-4">
                    <FormField
                      control={signupForm.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-gray-600">
                            Password
                          </FormLabel>
                          <div className="mx-auto flex w-full max-w-md space-x-2">
                            <FormControl>
                              <Input type="password" {...field} />
                            </FormControl>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="space-y-4">
                    <FormField
                      control={signupForm.control}
                      name="confirm_password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel className="text-gray-600">
                            Confirm Password
                          </FormLabel>
                          <div className="mx-auto flex w-full max-w-md space-x-2">
                            <FormControl>
                              <Input type="password" {...field} />
                            </FormControl>
                          </div>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="space-y-4">
                  <div>
                    <Button
                      type="submit"
                      className="w-full rounded-md px-8 py-3 font-semibold"
                    >
                      {signUpClicked ? <LoadingDots /> : `Sign Up`}
                    </Button>
                  </div>
                </div>
              </form>
            </Form>
            <div className="flex justify-center pt-4">
              <button type="button" onClick={() => setMode("LOGIN")}>
                <p className="text-center text-sm dark:text-gray-400">
                  Remembered your password?
                </p>
              </button>
            </div>
          </>
        )}
        {mode === "PASSWORD" && (
          <>
            <div className="mb-8 text-center">
              <h1 className="my-3 text-4xl font-bold">
                {sharedLoginConfig.passwordReset}
              </h1>
              <Form {...initalizePasswordResetForm}>
                <form
                  onSubmit={initalizePasswordResetForm.handleSubmit(
                    passwordReset,
                  )}
                  className="space-y-4"
                >
                  <div className="space-y-4 text-left">
                    <div>
                      <FormField
                        control={initalizePasswordResetForm.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-gray-600">
                              Email
                            </FormLabel>
                            <div className="mx-auto flex w-full max-w-md space-x-2">
                              <FormControl>
                                <Input type="email" {...field} />
                              </FormControl>
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="space-y-4">
                      <div>
                        <Button
                          type="submit"
                          className="w-full rounded-md px-8 py-3 font-semibold"
                        >
                          {initalizePasswordResetClicked ? (
                            <LoadingDots />
                          ) : (
                            `Submit`
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </Form>
            </div>
            <button onClick={() => setMode("LOGIN")}>
              <p className="px-6 text-center text-sm dark:text-gray-400">
                Remembered your password?
              </p>
            </button>
          </>
        )}
        {mode === "UPDATE_PASSWORD" && (
          <>
            <div className="mb-8 text-center">
              <h1 className="my-3 text-4xl font-bold">
                {sharedLoginConfig.setPasswordReset}
              </h1>
              <Form {...passwordResetForm}>
                <form
                  onSubmit={passwordResetForm.handleSubmit(updatePassword)}
                  className="space-y-4"
                >
                  <div className="space-y-4 text-left">
                    <div>
                      <FormField
                        control={passwordResetForm.control}
                        name="password"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel className="text-gray-600">
                              Password
                            </FormLabel>
                            <div className="mx-auto flex w-full max-w-md space-x-2">
                              <FormControl>
                                <Input
                                  type={togglePassword ? "text" : "password"}
                                  maxLength={100}
                                  {...field}
                                />
                              </FormControl>
                              <PasswordButtonToggle
                                onClick={() =>
                                  setTogglePassword(!togglePassword)
                                }
                                open={togglePassword}
                              />
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="space-y-4">
                      <div>
                        <Button
                          type="submit"
                          className="w-full rounded-md px-8 py-3 font-semibold"
                        >
                          {passwordResetClicked ? <LoadingDots /> : `Submit`}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </Form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LoginSection;
