import Image from "next/image";

const SiteLogo = () => {
  const APP_ID = process.env.NEXT_PUBLIC_APP_ID;

  return (
    <Image
      className="h-[40px] w-[40px]"
      src={`/images/${APP_ID}.png`}
      alt="Logo"
      height={40}
      width={40}
      priority
    />
  );
};

export default SiteLogo;
