import { sharedLoginConfig } from "@/config/shared";
import * as z from "zod";

export const SignInFormSchema = z.object({
  email: z
    .string({
      required_error: sharedLoginConfig.emailRequiredError,
    })
    .email(),
  password: z.string({
    required_error: sharedLoginConfig.passwordRequiredError,
  }),
});

export const SignUpFormSchema = z
  .object({
    email: z
      .string({
        required_error: sharedLoginConfig.emailRequiredError,
      })
      .email(),
    password: z.string({
      required_error: sharedLoginConfig.passwordRequiredError,
    }),
    confirm_password: z.string({
      required_error: sharedLoginConfig.confirmPasswordRequiredError,
    }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirmPassword"], // path of error
  });

export const InitializePasswordResetSchema = z.object({
  email: z
    .string({
      required_error: sharedLoginConfig.emailRequiredError,
    })
    .email(),
});

export const PasswordResetSchema = z.object({
  password: z.string({
    required_error: sharedLoginConfig.passwordRequiredError,
  }),
});
