import { createBrowserClient } from "@supabase/ssr";
import { cache } from "react";

export const createClient = () => {
  // const development = process.env.NODE_ENV === "development";

  return createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
  );
};

export const getUserSession = cache(async () => {
  const supabase = createClient();
  try {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    return user;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
});
